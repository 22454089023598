import socketIO from "socket.io-client";
import { makeAutoObservable, runInAction } from "mobx";
import moment from "moment";
import { config } from "./config";
import menu_items from "../assets/menu.json";
//const socket = socketIO.connect('http://127.0.0.1:3001');
class StateManager {
  constructor() {
    this.dashboardData = null;
    this.initiated = false;
    this.ordersPace = null;
    this.logs = [];
    this.insightsData = [];
    this.currentTime = "00:00";
    this.connection_error_timer = null;
    this.menuItems = [];
    this.statsData = null;
    this.stockInfo = null;
    this.dashboardStats = {
      checkout: { count: 0 },
      browsing: { count: 0 },
      orders_info: { count: 0, amount: 0 },
    };
    this.weeklyStatistics = null;
    this.stockGraph = [];
    this.dailyEstimations = null;
    this.channelInfo = null;
    this.alerts = {};
    this.xDaysSales = null;
    this.yesterdayGMV = null;
    this.atomicPreviousMonth = null;
    this.showStoppersConfig = this.setShowStoppers();
    //this.alerts[StateManager.Alerts.PACE] = { active: false, message: "" };
    this.failedToLoad = {};

    this.connection_error = false;
    const self = this;
    this.objectCount = Object.keys(StateManager.Keys).length;
    this.alert_config = {};
    this.alert_config[StateManager.Alerts.PACE] = {
      retries: 1,
      numberOfHits: 0,
    };
    this.connectSocket();
    let date_time = moment.utc().format("DD/MM/yyyy HH:mm:ss");
    this.loadMenuItems();
    makeAutoObservable(this);
    this.currentTime = date_time;
    //this.registerSockets();
  }
  updateConfig(jsonToSave) {
    runInAction(() => {
      config.saveConfig(jsonToSave);
    });
  }
  isStreamSuccess(...args) {
    let successState = true;
    for (let i = 0; i < args.length; i++) {
      let currentKey = args[i];
      if (this.failedToLoad[currentKey]) {
        if (!this.failedToLoad[currentKey].isSuccess) {
          successState = successState && true;
        } else {
          successState = successState && false;
        }
      }
    }

    return successState;
  }
  connectSocket() {
    let self = this;
    this.socket = socketIO.connect(process.env.REACT_APP_IP, {
      transports: ["websocket"],
    });
    this.socket.on("connect", (arg) => {
      //stuff
      self.socketDisconnected = false;
      self.updateConnectionError(false);
    });
    this.socket.on("disconnect", (reason) => {
      console.log("socket disconnected");
      self.socketDisconnected = true;
    });
    this.socket.on("connect_error", (err) => {
      self.updateConnectionError(true);
      console.log(`connect_error due to ${err.message}`);
    });

    //this.registerSockets();
  }
  getMenuItemState(key) {
    let item = this.menuItems.find((item) => item.key === key);
    if (item) {
      return item.state;
    }
    return null;
  }
  findMenuItem(key) {
    //NEED TO look into menuItems and subItems
    let item = this.menuItems.find((item) => item.key === key);
    if (item) {
      return item;
    } else {
      for (let i = 0; i < this.menuItems.length; i++) {
        let subItems = this.menuItems[i].subItems;
        if (subItems) {
          let subItem = subItems.find((item) => item.key === key);
          if (subItem) {
            return subItem;
          }
        }
      }
    }
  }
  getMenuItemLogicalState(key) {
    let item = this.findMenuItem(key);
    if (item) {
      return item.logicalState;
    }
    return null;
  }
  onMenuItemClicked(item) {
    //get reference to menu item
    let menuItem = this.findMenuItem(item.key);
    if (menuItem) {
      if (menuItem.state !== StateManager.MenuStates.STATIC) {
        menuItem.state =
          menuItem.state === StateManager.MenuStates.CLICKED
            ? StateManager.MenuStates.INITIAL
            : StateManager.MenuStates.CLICKED;
      }
      menuItem.logicalState =
        menuItem.logicalState === StateManager.MenuLogicalStates.ENABLED
          ? StateManager.MenuLogicalStates.DISABLED
          : StateManager.MenuLogicalStates.ENABLED;
      this.menuItems = JSON.parse(JSON.stringify(this.menuItems));

      this.storeMenuItemsStates();
    }
  }
  getMenuItemsStatesFromStore() {}
  storeMenuItemsStates() {
    //store logical and state
    let storedItems = [];
    this.menuItems.forEach((item) => {
      //need to flatten item.subItems

      if (item.subItems) {
        item.subItems.forEach((subItem) => {
          storedItems.push({
            key: subItem.key,
            state: subItem.state,
            logicalState: subItem.logicalState,
          });
        });
      }
      let storedData = {
        key: item.key,
        state: item.state,
        logicalState: item.logicalState,
      };
      storedItems.push(storedData);
      //return [storedData, ...subItems];
    });

    localStorage.setItem("dashboardMenuItems", JSON.stringify(storedItems));
  }

  loadMenuItems() {
    //need to merge with the config in local storage
    //config is [{key:GLOBE_ROTATION, state:CLICKED},{key:GLOBE_ACTIVATION, state:INITIAL]

    //get from store
    let menuItemsStates = localStorage.getItem("dashboardMenuItems");
    if (menuItemsStates) {
      menuItemsStates = JSON.parse(menuItemsStates);
      //merge
      let menuItems = menu_items.map((item) => {
        //find item in menuItemsStates
        if (item.subItems) {
          let subItems = item.subItems.map((subItem) => {
            let itemState = menuItemsStates.find(
              (stateItem) => stateItem.key === subItem.key
            );
            if (itemState) {
              subItem.state = itemState.state;
              subItem.logicalState = itemState.logicalState;
            } else {
              //get from item
              //  subItem.state = subItem.state;
              // subItem.logicalState = subItem.logicalState;
            }
            return subItem;
          });
          item.subItems = subItems;
        }
        let itemState = menuItemsStates.find(
          (stateItem) => stateItem.key === item.key
        );
        if (itemState) {
          item.state = itemState.state;
          item.logicalState = itemState.logicalState;
        } else {
          //item.state = item.state;
          //item.logicalState = item.logicalState;
        }
        return item;
      });
      this.menuItems = menuItems;
    } else {
      this.menuItems = menu_items;
    }
  }

  setShowStoppers() {
    let components = {};
    components[StateManager.Keys.DashboardData] = true;
    components[StateManager.Keys.SessionsStats] = false;
    components[StateManager.Keys.DashboardStats] = true;
    components[StateManager.Keys.DashboardWeekly] = true;
    components[StateManager.Keys.OrdersPace] = true;
    components[StateManager.Keys.RoutesInfo] = true;
    components[StateManager.Keys.InsightsData] = true;
    components[StateManager.Keys.AtomicMonthly] = true;
    components[StateManager.Keys.Stock] = false;
    components[StateManager.Keys.StockGraph] = false;
    components[StateManager.Keys.DailyEstimations] = true;
    components[StateManager.Keys.AtomicPreviousMonth] = true;
    components[StateManager.Keys.XDaysSales] = true;
    components[StateManager.Keys.YesterdayGMV] = false;
    return components;
  }
  updateConnectionError(value) {
    runInAction(() => {
      this.connection_error = value;
    });
  }

  startListening() {
    this.registerSockets();
  }

  simulateMenuClick(key) {
    //alert(key);
    //debugger;
    //NEED TO LOAD MENU FIRST, NEED TO FIND IT IN THE CODE...
    //document.querySelector(`[data-key=${key}]`).click();
  }

  getCachedData(item, defaultValue = null) {
    /*
        let data = null;
        if (data =localStorage.getItem(item)){             
            return JSON.parse(data);
        }
        */
    return defaultValue;
  }
  updateFailedObject(key, isSuccess) {
    runInAction(() => {
      if (isSuccess) {
        //remove key if exists
        if (this.failedToLoad[key]) {
          delete this.failedToLoad[key];
          this.failedToLoad = JSON.parse(JSON.stringify(this.failedToLoad));
        }
      } else {
        this.failedToLoad[key] = {
          isSuccess: false,
          isShowStopper: this.showStoppersConfig[key],
          cb: new Date().getTime(),
        };

        //set with new object instance using json
        this.failedToLoad = JSON.parse(JSON.stringify(this.failedToLoad));
      }
    });
  }
  updateLog(log, color) {
    let hour = moment.utc().format("HH:mm:ss");
    let date_time = moment.utc().format("DD/MM/yyyy HH:mm:ss");
    this.currentTime = date_time;
    this.logs.push({ hour, log, color });
  }
  setAll(data) {
    runInAction(() => {
      this.dashboardData = data[StateManager.Keys.DashboardData];
      this.statsData = data[StateManager.Keys.SessionsStats];
      this.dashboardStats = data[StateManager.Keys.DashboardStats].sessions;
      this.weeklyStatistics = data[StateManager.Keys.DashboardWeekly];
      this.routesInfo = data[StateManager.Keys.RoutesInfo];
      this.insightsData = data[StateManager.Keys.InsightsData];
      this.atomicMonthly = data[StateManager.Keys.AtomicMonthly];
      this.ordersPace = data[StateManager.Keys.OrdersPace];
      this.stockInfo = data[StateManager.Keys.Stock];
      this.stockGraph = data[StateManager.Keys.StockGraph];
      this.dailyEstimations = data[StateManager.Keys.DailyEstimations];
      this.channelInfo = data[StateManager.Keys.ChannelInfo];
      this.atomicPreviousMonth = data[StateManager.Keys.AtomicPreviousMonth];
      this.opm_avg = data[StateManager.Keys.OPM_AVG];
      this.xDaysSales = data[StateManager.Keys.XDaysSales];
      this.yesterdayGMV = data[StateManager.Keys.YesterdayGMV];
    });
  }

  getNewObject(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  updateAlerts(alerts) {
    this.alerts = this.getNewObject(alerts);
  }
  checkForAlerts(key, data) {
    runInAction(() => {
      if (!config.initiated) return;

      try {
        switch (key) {
          case StateManager.Alerts.PACE:
            let pace = data.last_minute?.ordersLastMinute;
            //get current time mean from opm_avg
            let currentTime = moment.utc().format("HH:mm");
            let currentTimeInfo = this.opm_avg[currentTime];
            let paceToCompare = config.PACE_ALERT_THRESHOLD;
            if (currentTimeInfo) {
              paceToCompare = currentTimeInfo.mean;
              let threshold =
                config.paceMeanThreshold !== undefined
                  ? config.paceMeanThreshold
                  : 0.4;
              //reduce 40% from mean as the threshold
              paceToCompare = paceToCompare * (1 - threshold);
            }

            //pace = 2;
            if (pace < paceToCompare) {
              //check alerts config to see if we reached the limit
              let alertConfig = this.alert_config[StateManager.Alerts.PACE];
              if (alertConfig.numberOfHits >= alertConfig.retries) {
                //get percentage diff between pace and paceToCompare
                let percentage = (pace / paceToCompare) * 100;

                this.alerts[StateManager.Alerts.PACE] = {
                  active: true,
                  popup: false,
                  name: "Incoming Orders",
                  message: `Incoming orders pace is low (${(
                    100 - percentage
                  ).toFixed(0)}% below average), please check the checkout!`,
                };
                this.alerts = this.getNewObject(this.alerts);

                //reset alertConfig
                alertConfig.numberOfHits = 0;
              } else {
                alertConfig.numberOfHits++;
              }
            } else {
              this.alerts[StateManager.Alerts.PACE] = {
                active: false,
                name: "Incoming Orders",
                message:
                  "Incoming orders pace is low, please check the checkout!",
              };
              this.alerts = this.getNewObject(this.alerts);
            }
            break;
          case StateManager.Alerts.DASHBOARD_STATS:
            let checkout_sessions = data.checkout.count;
            let browsing_sessions = data.browsing.count;

            if (checkout_sessions < config.CHECKOUT_SESSIONS_THRESHOLD) {
              this.alerts[StateManager.Alerts.CHECKOUT_SESSIONS] = {
                active: true,
                popup: true,
                name: "Checkout Sessions",
                message:
                  "Checkout sessions are low, please check the checkout!",
              };
            } else {
              this.alerts[StateManager.Alerts.CHECKOUT_SESSIONS] = {
                active: false,
                name: "Checkout Sessions",
                message:
                  "Checkout sessions are low, please check the checkout!",
              };
            }

            if (browsing_sessions < config.BROWSING_SESSIONS_THRESHOLD) {
              this.alerts[StateManager.Alerts.BROWSING_SESSIONS] = {
                active: true,
                name: "Browsing Sessions",
                message:
                  "Browsing sessions are low, please check the checkout!",
              };
            } else {
              this.alerts[StateManager.Alerts.BROWSING_SESSIONS] = {
                active: false,
                name: "Browsing Sessions",
                message:
                  "Browsing sessions are low, please check the checkout!",
              };
            }
            this.alerts = this.getNewObject(this.alerts);
            break;
          default:
            //do nothing
            break;
        }
      } catch (e) {
        console.log("error checking for alerts", e);
      }
    });
  }

  checkForErrors(key, data) {
    if (data.error) {
      this.updateFailedObject(key, false);
    } else {
      this.updateFailedObject(key, true);
      //check if current object is in failed to load, if so, update it to success
      // if (this.failedToLoad[key] && this.failedToLoad[key].isSuccess === false)
      // {
      //     this.updateFailedObject(key,true);
      // }
    }
  }

  getCurrentTime() {
    let currentTimeHHMM = moment.utc().format("HH:mm");
    return currentTimeHHMM;
  }

  registerSockets() {
    this.socket.on("config_updated", (configuration) => {
      config.update(configuration);
    });
    this.socket.on("dashboard_message", (message) => {
      this.socket.emit("activity", "keepalive");
      switch (message.type) {
        case StateManager.Keys.DashboardData:
          runInAction(() => {
            this.checkForErrors(StateManager.Keys.DashboardData, message.data);
            this.dashboardData = message.data;

            this.updateLog("dashbaord date updated", "#599955");
          });

          //localStorage.setItem('dashboardData', JSON.stringify(message.data));
          break;
        case StateManager.Keys.SessionsStats:
          runInAction(() => {
            this.checkForErrors(StateManager.Keys.SessionsStats, message.data);
            this.statsData = message.data;
            this.updateLog("sessions statistics updated", "green");
          });
          break;
        case StateManager.Keys.DashboardStats:
          runInAction(() => {
            this.checkForErrors(StateManager.Keys.DashboardStats, message.data);
            this.dashboardStats = message.data.sessions;
            //console.log("dashboard stats", JSON.stringify(this.dashboardStats));
            this.updateLog("dashboard statistics updated", "lightgreen");
            this.checkForAlerts(
              StateManager.Alerts.DASHBOARD_STATS,
              message.data.sessions
            );
          });

          break;
        case StateManager.Keys.DashboardWeekly:
          runInAction(() => {
            this.checkForErrors(
              StateManager.Keys.DashboardWeekly,
              message.data
            );
            this.weeklyStatistics = message.data;
            this.updateLog("dashboard weekly statistics updated", "#00FD15");
          });

          break;
        case StateManager.Keys.OrdersPace:
          runInAction(() => {
            this.checkForErrors(StateManager.Keys.OrdersPace, message.data);
            this.ordersPace = message.data;
            this.updateLog("order counters updated", "#00A80E");
            this.checkForAlerts(StateManager.Alerts.PACE, message.data);
          });
          break;
        case StateManager.Keys.RoutesInfo:
          runInAction(() => {
            this.checkForErrors(StateManager.Keys.RoutesInfo, message.data);
            this.routesInfo = message.data;
            this.updateLog("order routes updated", "#fff");
          });
          break;
        case StateManager.Keys.InsightsData:
          runInAction(() => {
            this.checkForErrors(StateManager.Keys.InsightsData, message.data);
            this.insightsData = message.data;
            this.updateLog("order routes updated", "#fff");
          });
          break;
        case StateManager.Keys.AtomicMonthly:
          runInAction(() => {
            this.checkForErrors(StateManager.Keys.AtomicMonthly, message.data);
            this.atomicMonthly = message.data;
            this.atomicMonthly.update_hour = this.getCurrentTime();
            this.updateLog("order routes updated", "#fff");
          });
          break;
        case StateManager.Keys.Stock:
          runInAction(() => {
            this.checkForErrors(StateManager.Keys.Stock, message.data);
            this.stockInfo = message.data;
            this.updateLog("stock data updated", "#fff");
          });
          break;
        case StateManager.Keys.StockGraph:
          runInAction(() => {
            this.checkForErrors(StateManager.Keys.StockGraph, message.data);
            this.stockGraph = message.data;
            this.updateLog("stock graph data updated", "#fff");
          });
          break;
        case StateManager.Keys.DailyEstimations:
          runInAction(() => {
            this.checkForErrors(
              StateManager.Keys.DailyEstimations,
              message.data
            );
            this.dailyEstimations = message.data;
            //this.atomicMonthly.update_hour = this.getCurrentTime();
            this.updateLog("daily estimations updated", "#fff");
          });
          break;
        case StateManager.Keys.ChannelInfo:
          runInAction(() => {
            this.checkForErrors(StateManager.Keys.ChannelInfo, message.data);
            this.channelInfo = message.data;
            //this.atomicMonthly.update_hour = this.getCurrentTime();
            this.updateLog("channel info updated", "#fff");
          });
          break;
        case StateManager.Keys.AtomicPreviousMonth:
          runInAction(() => {
            this.checkForErrors(
              StateManager.Keys.AtomicPreviousMonth,
              message.data
            );
            this.atomicPreviousMonth = message.data;

            //this.atomicMonthly.update_hour = this.getCurrentTime();
            this.updateLog("previous month data updated", "#fff");
          });
          break;
        case StateManager.Keys.OPM_AVG:
          runInAction(() => {
            this.checkForErrors(StateManager.Keys.OPM_AVG, message.data);
            this.opm_avg = message.data;

            //this.atomicMonthly.update_hour = this.getCurrentTime();
            this.updateLog("opm average", "#fff");
          });
          break;
        case StateManager.Keys.XDaysSales:
          runInAction(() => {
            this.checkForErrors(StateManager.Keys.XDaysSales, message.data);
            this.xDaysSales = message.data;
            debugger;
            this.updateLog("x days sales", "#fff");
          });
          break;
        case StateManager.Keys.YesterdayGMV:
          runInAction(() => {
            this.checkForErrors(StateManager.Keys.YesterdayGMV, message.data);
            this.yesterdayGMV = message.data;
            debugger;
            this.updateLog("yesterday GMV", "#fff");
          });
          break;
        default:
          //do nothing
          break;
      }
    });
    this.initiated = true;
  }
}
StateManager.Alerts = {
  PACE: "pace",
  DASHBOARD_STATS: "dashboard_stats",
  CHECKOUT_SESSIONS: "checkout_sessions",
  BROWSING_SESSIONS: "browsing_sessions",
};

StateManager.Keys = {
  DashboardWeekly: "dashboard_weekly",
  SessionsStats: "sessions_stats",
  DashboardData: "dashboard_data",
  DashboardStats: "dashboard_stats",
  OrdersPace: "orders_pace",
  RoutesInfo: "routes_info",
  InsightsData: "insights_data",
  AtomicMonthly: "atomic_monthly",
  Stock: "stock_data",
  StockGraph: "stock_graph",
  DailyEstimations: "daily_estimations",
  ChannelInfo: "channel_info",
  AtomicPreviousMonth: "atomic_previous_month",
  OPM_AVG: "opm_avg",
  XDaysSales: "x_days_sales",
  YesterdayGMV: "yesterday_gmv",
};

StateManager.MenuKeys = {
  GLOBE_ROTATION: "GLOBE_ROTATION",
  GLOBE_ACTIVATION: "GLOBE_ACTIVATION",
  PIES_ANIMATION: "PIES_ANIMATION",
  INSIGHTS_ENABLE: "INSIGHTS_ENABLE",
  FULL_SCREEN_ACTIVATION: "FULL_SCREEN_ACTIVATION",
  CONFIGURATION: "CONFIGURATION",
  STOCK_INFO: "STOCK_INFO",
  INSIGHTS_STYLE: "INSIGHTS_STYLE",
  RELEASE_NOTES: "RELEASE_NOTES",
  GLOBE_FPS: "GLOBE_FPS",
  DAILY_ESTIMATION: "DAILY_ESTIMATION",
  LOGOUT: "LOGOUT",
  USER_MANAGEMENT: "USER_MANAGEMENT",
};
StateManager.MenuLogicalStates = {
  DISABLED: "DISABLED",
  ENABLED: "ENABLED",
};

StateManager.MenuStates = {
  INITIAL: "INITIAL",
  CLICKED: "CLICKED",
  STATIC: "STATIC",
  DIAL: "DIAL",
};
const state = new StateManager();
export { state };
export { StateManager };
